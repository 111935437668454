<template>
  <priv-publ-cloud />
</template>

<script>
import PrivPublCloud from "../../components/solutions/cloud-solutions/PrivPublCloud.vue";
export default {
  name: "private-public-cloud-page",
  components: { PrivPublCloud },
};
</script>
